import {SiteConfigContextValue} from '@common/core/settings/site-config-context';
import {RegisterPageFields} from '@app/register-page-fields';
import {message} from '@common/i18n/message';
import {CapnisIcon} from '@common/icons/social/capnis';
import {AccountSettingsPurchasesPanel} from '@app/account-settings-purchases-panel/account-settings-purchases-panel';

export const SiteConfig: Partial<SiteConfigContextValue> = {
  auth: {
    redirectUri: '/',
    adminRedirectUri: '/admin',
    registerFields: RegisterPageFields,
    accountSettingsPanels: [
      {
        icon: CapnisIcon,
        label: message('Your purchases'),
        component: AccountSettingsPurchasesPanel,
        id: 'purchases',
      },
    ],
  },
  homepage: {
    options: [
      {
        label: message('Landing page'),
        value: 'landingPage',
      },
    ],
  },
  admin: {
    ads: [],
  },
  tags: {
    types: [{name: 'category'}, {name: 'status'}],
  },
  demo: {
    loginPageDefaults: 'singleAccount',
    email: 'admin@demo.com',
    password: 'demo',
  },
  settings: {
    showIncomingMailMethod: true,
  },
};
